import React from 'react'


const Projelercart = ({ img, title, text }) => {
    let bg = 'bg-transparent'

if (title === 'konumu') {
    bg = 'bg-blue-100'
}
if (title === 'soranvar') {
    bg = 'bg-red-600'
}
    return (
        <div
            data-aos='fade-up'
            data-aos-anchor-placement='center-bottom'
            className={`h-auto lg:my-10 lg:h-[20rem] container text-white   p-5 overflow-y-auto  lg:overflow-hidden flex flex-col lg:flex-row  justify-center gap-5  items-center   border lg:rounded-3xl  w-full bg-slate-200
                }`}
        >
            <div className='w-full lg:w-1/2 flex justify-center items-center ' >
                <div className={` ${bg} p-4 rounded-full  flex justify-center items-center`} >

                    <img src={img} className='object-cover object-center h-14  mx-auto lg:h-20  ' alt="" />
                </div>
            </div>

            <div className='w-full lg:w-1/2 text-black font-serif ' >
                <h4 className='text-2xl text-center my-5 capitalize' >{title}</h4>
                <p className='text-center' >{text}</p>
            </div>
        </div>
    )
}

export default Projelercart