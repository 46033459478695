import React, { useEffect, useRef, useState } from 'react'
import Header from '../layouts/header/Header'
import TextAnimation from '../toolbox/TextAnimation'
import Footer from '../layouts/footer/Footer'
import Projelercart from '../components/home-cart/Projelercart'

import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  AiOutlineMenu,
  AiOutlineWhatsApp,
  AiOutlinePhone
} from 'react-icons/ai'
import { CiUnlock } from 'react-icons/ci'
import { Link } from 'react-router-dom'
const About = ({ theme, setTheme }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const myInputRef = useRef(null)
  useEffect(() => {
    myInputRef.current.focus()
    AOS.init()
  }, [])
  return (
    <div>
      <Header themeChange={setTheme} theme={theme} />
      <button
        data-aos='fade-up'
        data-aos-easing='ease-in-sine'
        onClick={() => setOpenMenu(!openMenu)}
        className='bg-slate-500 hover:bg-slate-700 hover:animate-spin text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button'
      >
        {openMenu ? <CiUnlock /> : <AiOutlineMenu />}
      </button>
      {openMenu && (
        <div>
          <Link
            data-aos='fade-right'
            data-aos-easing='ease-in-sine'
            to={'https://api.whatsapp.com/send?phone=905321349003'}
            className='bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button1'
          >
            <AiOutlineWhatsApp />
          </Link>

          <Link
            to={'tel:+9005321349003'}
            data-aos='fade-left'
            data-aos-easing='ease-in-sine'
            className='bg-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-4 px-4 rounded-full fixed-button2'
          >
            <AiOutlinePhone />
          </Link>
        </div>
      )}
      <div className='flex flex-col rounded-2xl   w-full lg:px-10     justify-center items-center'>
        <div className=' container p-5 rounded-2xl  shadow-md border flex flex-col '>
          <span
            ref={myInputRef}
            tabIndex={0}
            className='text-3xl text-center mb-10'
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-easing='ease-in-sine'
          >
            Fuat Ayaz Group Bilişim Teknolojileri A.Ş.
          </span>
          <span
            className=''
            data-aos='fade-up'
            data-aos-offset='300'
            data-aos-easing='ease-in-sine'
          >
            Fuat Ayaz Group, farklı sektörlerde faaliyet gösteren işletmelerin geniş finansal ihtiyaçlarına destek sağlayar. Başarı odaklı yaklaşımı ve kapsamlı finansal çözümleriyle işletmelere destek sağlarken sektörler arası işbirliğini güçlendirir. Geniş bir sektörel yelpazede faaliyet gösterir, Teknoloji şirketlerinden Tarım şirketlerine kadar geleceğe yönelik projeleri olan çeşitli işletmeler ve kişilere destek sunar. Bu yaklaşım, sürdürülebilirliği destekleyerek güçlü bir ekonomik altyapı oluşturur. Fuat Ayaz Group Finansal çözüm arayışında olanlar için önemli bir kaynak konumundadır.

            Fuat Ayaz Group Bilişim Teknolojileri A.Ş., ileri teknoloji
            çözümleriyle donanmış köklü ve yenilikçi bir şirketler grubudur .
            Fikirlerinizi destekliyor yatırım sağlayarak iş hayatınızda bir adım
            önde olmanızı hedefliyoruz bir çok kişiye ve şirketlere yaptığımız
            yatırım desteği ile hedeflerine ulaşmalarını sağladık bir çok kişi
            ve şirketleri yatırımcıyla buluşturarak ortak kazanç sistemi
            sağladık ve Yenilik ve mükemmellik vizyonumuzu destekleyen temel
            prensiplerimizle, farklı sektörlerdeki başarıları bir araya
            getirerek müşterilerimize en üst düzeyde değer sunmaya kararlıyız.
            Geleceğe yönelik çözümlerimizle, iş dünyasının hızla değişen
            ihtiyaçlarını karşılıyor, dönüşüm ve dönüşüm liderliğini
            destekliyoruz. Teknolojiyi herkesin erişebileceği bir güç haline
            getiriyoruz ve bu gücü iş dünyasını dönüştürmek, toplumu
            kalkındırmak ve yaşamları kolaylaştırmak için kullanıyoruz. Fuat
            Ayaz Group Bilişim Teknolojileri A.Ş., bünyesinde barındırdığı
            farklı markalar ve şirketlerle geniş bir yelpazede hizmetler
            sunmaktadır. Her bir markamız, kendi alanında uzmanlaşmış ve
            sektörde öncü konumda olan bir liderdir. Bir araya getirdiğimiz bu
            güçlü markalar sayesinde, müşterilerimize özelleştirilmiş çözümler
            sunarak, iş süreçlerini optimize etmelerine, verimliliklerini
            artırmalarına ve rekabette öne çıkmalarına destek oluyoruz.
            Tecrübeli ve uzman kadromuzla müşteri odaklı yaklaşımımızı temel
            alıyoruz. Müşterilerimizin ihtiyaçlarını anlamak ve onlara en uygun
            çözümleri sunmak için özenle çalışıyoruz. Değerlerimize bağlı
            olarak, dürüstlük, şeffaflık ve iş ahlakına önem veriyoruz. Müşteri
            memnuniyetini en üst düzeyde tutmak için sürekli gelişen bir
            organizasyon olarak, müşterilerimizle güvene dayalı
            <TextAnimation
              text={` uzun vadeli iş ilişkileri kurmaya önem veriyoruz. Toplumsal sorumluluk bilincine sahibiz ve sosyal kalkınmayı destekleyen projelerde yer alıyoruz. Sürdürülebilirlik odaklı çalışmalarımızla çevreye ve topluma olan katkımızı artırıyoruz. Fuat Ayaz Group Bilişim Teknolojileri A.Ş. olarak misyonumuz, teknolojiyi insanların hayatlarını kolaylaştırmak, iş dünyasını dönüştürmek ve topluma değer katmak için kullanmaktır. Vizyonumuz, teknolojideki sınırları zorlayarak, dünya çapında tanınan ve tercih edilen bir şirket grubu olmaktır. Tüm paydaşlarımıza, iş ortaklarımıza ve çalışanlarımıza olan bağlılığımızla, güçlü bir gelecek inşa etmek için el birliğiyle çalışmaya devam ediyoruz. Fuat Ayaz Group Bilişim Teknolojileri A.Ş. olarak, başarılarımızı müşterilerimizin başarılarıyla ölçüyor, onların hedeflerine ulaşmasında güçlü bir destek olmaktan gurur duyuyoruz.`}
            />
          </span>
        </div>

        <div
          data-aos='fade-right'
          data-aos-offset='300'
          data-aos-easing='ease-in-sine'
          className=' text-2xl lg:text-5xl mt-5 font-semibold'
        >
          Destek Verdiğimiz Projeler
        </div>
        <Projelercart img='https://bendebu.com/logo.png' title='Bendebu' text={"Evinizde kullanmadığınız eşyalar mı var? Hızlıca satmak istediğiniz ürünleri kolayca platformumuz aracılığıyla yayınlayabilir, geniş kullanıcı kitlesiyle buluşturabilirsiniz. Ayrıca, aradığınız ürünü uygun fiyatlarla bulabileceğiniz birçok seçenek de sizleri bekliyor olacak."} />

        <Projelercart img='/img/sorgubox.png' title='Sorgubox' text={"Teknolojiyi kullanırken güvenlik her zaman öncelik olmalıdır. Mobil Cihaz Güvenliği Platformumuz, size akıllı telefonlarınızın ve diğer mobil cihazlarınızın güvenliğini sağlamanız için gereken her şeyi sunuyor."} />

        <Projelercart img='https://konumu.com/image/logo.png' title='konumu' text={"Sizin için en güzel ve ilginç mekanları bulmak artık çok kolay! Uygulamamız sayesinde bulunduğunuz lokasyonda size en yakın ve en popüler mekanları keşfedebilirsiniz. Yorumlar, fotoğraflar ve önerilerle mekanları daha yakından tanıyabilir, keyifli zaman geçirebilirsiniz."} />
        <Footer />
      </div>
    </div>
  )
}

export default About
